"use client";

import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { trpc } from "@/providers/TrpcProvider";
import { GetRsusReturnType } from "@/server/providers/rsus";
import dayjs from "dayjs";
import { Triangle } from "lucide-react";
import Link from "next/link";
import { useState } from "react";
import { useCurrentPrice } from "./CurrentPriceProvider";
export default function HomeStockPriceCard({
  rsu: defaultRsu,
  firstName
}: {
  rsu: GetRsusReturnType | null;
  firstName: string | null;
}) {
  const [isVestedOnly, setIsVestedOnly] = useState(false);
  const {
    currentPrice,
    lastMarketDayClosePrice
  } = useCurrentPrice();
  const {
    data: rsu
  } = trpc.rsu.getRsus.useQuery(undefined, {
    initialData: defaultRsu ?? undefined
  });
  const {
    data: marketStatus
  } = trpc.tickers.getMarketStatus.useQuery();
  const rsuAmount = (isVestedOnly ? rsu?.vestedRsu : rsu?.totalRsu) || 0;
  const lastMarketOpenPriceValue = lastMarketDayClosePrice || currentPrice;
  const lastMarketOpenPriceChangeDifference = (currentPrice - lastMarketOpenPriceValue) / lastMarketOpenPriceValue;
  const totalValueDifference = currentPrice * rsuAmount - lastMarketOpenPriceValue * rsuAmount;
  const lastMarketDay = dayjs(marketStatus?.lastMarketDay);
  const isToday = dayjs().isSame(lastMarketDay, "day");
  const isYesterday = dayjs().subtract(1, "day").isSame(lastMarketDay, "day");
  let changeDate = dayjs().format("MMM DD");
  if (isToday || marketStatus?.isAfterHours || marketStatus?.isMarketOpen) {
    changeDate = "Today";
  } else if (isYesterday) {
    changeDate = "Yesterday";
  } else {
    changeDate = lastMarketDay.format("MMM DD");
  }
  return <div className="flex flex-col p-4 max-w-screen-lg w-full grow mx-auto gap-4" data-sentry-component="HomeStockPriceCard" data-sentry-source-file="HomeStockPriceCard.tsx">
      <div className="flex flex-row justify-between">
        <p className="text-xl">
          {firstName ? `Hello, ${firstName}` : "Welcome to OnTrade"}
        </p>
        {rsu && <div className="flex flex-row items-center gap-1">
            <Switch id="vestedOnly" checked={isVestedOnly} onCheckedChange={setIsVestedOnly} />
            <Label htmlFor="vestedOnly" className="text-xs text-muted-foreground">
              Vested only
            </Label>
          </div>}
      </div>

      <div className="flex flex-row justify-between">
        <div>
          <div className="font-semibold text-xs text-muted-foreground">
            CURRENT PRICE
          </div>
          <p className="text-3xl">
            {currentPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2
          })}
          </p>

          <p className={cn("text-muted-foreground font-medium flex flex-row items-center gap-1 mt-1", lastMarketOpenPriceChangeDifference > 0 ? "text-green-600" : "text-red-600", lastMarketOpenPriceChangeDifference === 0 ? "text-muted-foreground" : "")}>
            {lastMarketOpenPriceChangeDifference !== 0 && <Triangle className={cn("w-3 h-3", lastMarketOpenPriceChangeDifference > 0 ? "text-green-600 fill-green-600" : "text-red-600 fill-red-600 rotate-180")} />}
            {lastMarketOpenPriceChangeDifference.toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}{" "}
            <span className="text-sm">{changeDate}</span>
            <span className={cn("text-xs", lastMarketOpenPriceChangeDifference > 0 ? "text-green-600" : "text-red-600", lastMarketOpenPriceChangeDifference === 0 && "text-muted-foreground")}></span>
          </p>
        </div>
        {rsu ? <div className="text-right">
            <div className="font-semibold text-xs text-muted-foreground">
              YOUR{" "}
              {rsuAmount.toLocaleString("en-US", {
            maximumFractionDigits: 0
          })}{" "}
              RSUS
            </div>
            <p className="text-3xl ">
              {(currentPrice * rsuAmount).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0
          })}
            </p>

            <p className={cn("text-muted-foreground font-medium flex flex-row-reverse items-center gap-1 mt-1", totalValueDifference > 0 ? "text-green-600" : "text-red-600", totalValueDifference === 0 ? "text-muted-foreground" : "")}>
              {totalValueDifference.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
              {totalValueDifference !== 0 && <Triangle className={cn("w-3 h-3", totalValueDifference > 0 ? "text-green-600 fill-green-600" : "text-red-600 fill-red-600 rotate-180")} />}
            </p>
          </div> : <div className="text-right">
            <div className="font-semibold text-xs text-muted-foreground">
              YOUR RSU VALUE
            </div>
            <Link href="/sign-up" className="text-3xl underline text-primary">
              Join
            </Link>
            <p className={cn("text-primary font-medium flex flex-row-reverse items-center gap-1 mt-1")}>
              to add yours
            </p>
          </div>}
      </div>
    </div>;
}