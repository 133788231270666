"use client";

import { GetAnalystEstimatesReturnType, GetPriceLikelihoodReturnType } from "@/lib/ontrade";
import { trpc } from "@/providers/TrpcProvider";
import { GetRiskRewardChartDataReturnType, GetScenarioPotentialsReturnType, GetSliderStepsReturnType } from "@/server/providers/scenarios";
import { GetEPSForPriceReturnType, GetMarketComparisonReturnType, GetMarketStatusReturnType } from "@/server/providers/tickers";
import { usePostHog } from "posthog-js/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
type ScenarioContextType = {
  ticker: string;
  scenarioSelectedValue: number;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  slideSteps: GetSliderStepsReturnType | undefined;
  defaultIndex: number;
  lastMarketOpenPrice: number | undefined | null;
  vestedOnly: boolean;
  setVestedOnly: (vestedOnly: boolean) => void;
  debouncedIndex: number;
  priceLikelihood: GetPriceLikelihoodReturnType | undefined;
  isPriceLikelihoodLoading: boolean;
  defaultPrice: number;
  riskRewardChartData: GetRiskRewardChartDataReturnType | undefined;
  isRiskRewardChartDataLoading: boolean;
  percentChange: number;
  negativePercentChange: number;
  negativeScenarioSelectedValue: number;
  scenarioPotentials: GetScenarioPotentialsReturnType | undefined;
  isScenarioPotentialsLoading: boolean;
  days: number;
  setDays: (days: number) => void;
  marketComparison: GetMarketComparisonReturnType | undefined;
  isMarketComparisonLoading: boolean;
  marketStatus: GetMarketStatusReturnType | undefined;
  isMarketStatusLoading: boolean;
  epsData: GetEPSForPriceReturnType | undefined;
  epsDataLoading: boolean;
  analystEstimates: GetAnalystEstimatesReturnType | undefined | null;
  isAnalystEstimatesLoading: boolean;
  isScenarioLoading: boolean;
  refetchLastMarketOpenPrice: () => void;
  refetchSlideSteps: () => void;
  refetchEpsData: () => void;
  refetchPriceLikelihood: () => void;
  refetchRiskRewardChartData: () => void;
  refetchScenarioPotentials: () => void;
  refetchMarketStatus: () => void;
  refetchAnalystEstimates: () => void;
  refetchMarketComparison: () => void;
};
const ScenarioContext = createContext<ScenarioContextType>({} as ScenarioContextType);
export default function ScenarioProvider({
  children,
  scenarioPotentials: defaultScenarioPotentials,
  marketComparison: defaultMarketComparison,
  marketStatus: defaultMarketStatus,
  analystEstimates: defaultAnalystEstimates,
  defaultSteps,
  initialIndex,
  ticker: defaultTicker,
  preserveIndex = false
}: {
  children: React.ReactNode;
  defaultSteps: GetSliderStepsReturnType | undefined;
  scenarioPotentials: GetScenarioPotentialsReturnType | undefined;
  marketComparison: GetMarketComparisonReturnType | undefined;
  marketStatus: GetMarketStatusReturnType | undefined;
  analystEstimates: GetAnalystEstimatesReturnType | undefined | null;
  ticker: string;
  initialIndex?: number;
  preserveIndex?: boolean;
}) {
  const {
    data: tickerAssignment
  } = trpc.tickers.getTickerAssignment.useQuery();
  const ticker = tickerAssignment?.ticker ?? defaultTicker;
  const {
    data: lastMarketOpenPrice,
    refetch: refetchLastMarketOpenPrice
  } = trpc.tickers.getPrevMarketDayClosePrice.useQuery({
    ticker
  }, {});
  const {
    data: slideSteps,
    refetch: refetchSlideSteps
  } = trpc.tickers.getSliderSteps.useQuery({
    ticker
  }, {
    initialData: defaultSteps
  });
  const defaultIndex = slideSteps?.defaultIndex ?? 0;
  const getLastScenarioIndex = () => {
    const item = (typeof localStorage !== "undefined" ? localStorage : undefined)?.getItem(`${ticker}:lastScenarioIndex`);
    if (typeof localStorage === "undefined") {
      console.log("localStorage undefined");
    }
    try {
      return parseInt(item ?? defaultIndex.toString());
    } catch (e) {
      return defaultIndex;
    }
  };
  const [currentIndex, setCurrentIndex] = useState(initialIndex ?? (preserveIndex ? getLastScenarioIndex() : defaultIndex));
  const [debouncedIndex] = useDebounce(currentIndex, 250);
  useEffect(() => {
    setCurrentIndex(initialIndex ?? (preserveIndex ? getLastScenarioIndex() : defaultIndex));
  }, [ticker, defaultIndex]);
  const defaultPrice = slideSteps?.steps[defaultIndex] ?? 0;
  const scenarioSelectedValue = useMemo(() => slideSteps?.steps[debouncedIndex] ?? 0, [slideSteps, debouncedIndex, ticker]);
  const [vestedOnly, setVestedOnly] = useState(false);
  const posthog = usePostHog();
  useEffect(() => {
    if (debouncedIndex !== defaultIndex) {
      posthog.capture("scenario_value_changed", {
        value: scenarioSelectedValue,
        ticker
      });
    }
  }, [debouncedIndex]);
  const {
    data: epsData,
    isFetching: isEPSDataLoading,
    refetch: refetchEpsData
  } = trpc.tickers.getEPSForPrice.useQuery({
    ticker,
    price: scenarioSelectedValue
  }, {
    enabled: defaultIndex !== debouncedIndex
  });
  const {
    data: priceLikelihood,
    isFetching: isPriceLikelihoodLoading,
    refetch: refetchPriceLikelihood
  } = trpc.tickers.getPriceLikelihood.useQuery({
    price: scenarioSelectedValue,
    direction: scenarioSelectedValue > defaultPrice ? "above" : "below",
    days: 90,
    ticker
  }, {
    enabled: defaultIndex !== debouncedIndex
  });
  const percentChange = (scenarioSelectedValue - defaultPrice) / defaultPrice;
  const negativeScenarioSelectedValue = defaultPrice * (1 - percentChange) < 0 ? 0 : defaultPrice * (1 - percentChange);
  const negativePercentChange = (negativeScenarioSelectedValue - defaultPrice) / defaultPrice;
  const {
    data: riskRewardChartData,
    isFetching: isRiskRewardChartDataLoading,
    refetch: refetchRiskRewardChartData
  } = trpc.tickers.getRiskRewardChartData.useQuery({
    priceAbove: scenarioSelectedValue > defaultPrice ? scenarioSelectedValue : negativeScenarioSelectedValue,
    priceBelow: scenarioSelectedValue < defaultPrice ? scenarioSelectedValue : negativeScenarioSelectedValue,
    ticker
  }, {
    enabled: defaultIndex !== debouncedIndex
  });
  const [days, setDays] = useState(90);
  const {
    data: scenarioPotentials,
    isFetching: isScenarioPotentialsLoading,
    refetch: refetchScenarioPotentials
  } = trpc.tickers.getScenarioPotentials.useQuery({
    ticker,
    days
  }, {
    enabled: defaultIndex !== debouncedIndex,
    initialData: defaultScenarioPotentials
  });
  const {
    data: marketStatus,
    isFetching: isMarketStatusLoading,
    refetch: refetchMarketStatus
  } = trpc.tickers.getMarketStatus.useQuery(undefined, {
    refetchInterval: 5 * 60 * 1000,
    initialData: defaultMarketStatus
  });
  const {
    data: analystEstimates,
    isFetching: isAnalystEstimatesLoading,
    refetch: refetchAnalystEstimates
  } = trpc.tickers.getAnalystEstimates.useQuery({
    ticker
  }, {
    initialData: defaultAnalystEstimates ?? undefined
  });
  const {
    data: marketComparison,
    isFetching: isMarketComparisonLoading,
    refetch: refetchMarketComparison
  } = trpc.tickers.getMarketComparison.useQuery({
    ticker
  }, {
    initialData: defaultMarketComparison
  });
  const isScenarioLoading = isPriceLikelihoodLoading || isRiskRewardChartDataLoading || isEPSDataLoading || isMarketStatusLoading || isMarketComparisonLoading || isAnalystEstimatesLoading || isScenarioPotentialsLoading;
  const setCurrentIndexWithLocalStorage = (index: number) => {
    localStorage.setItem(`${ticker}:lastScenarioIndex`, index.toString());
    setCurrentIndex(index);
  };
  return <ScenarioContext.Provider value={{
    ticker,
    defaultIndex,
    defaultPrice,
    lastMarketOpenPrice,
    scenarioSelectedValue,
    currentIndex,
    setCurrentIndex: setCurrentIndexWithLocalStorage,
    slideSteps,
    vestedOnly,
    setVestedOnly,
    debouncedIndex,
    priceLikelihood,
    isPriceLikelihoodLoading,
    riskRewardChartData,
    isRiskRewardChartDataLoading,
    percentChange,
    negativePercentChange,
    negativeScenarioSelectedValue,
    scenarioPotentials,
    isScenarioPotentialsLoading,
    days,
    setDays,
    marketComparison,
    isMarketComparisonLoading,
    marketStatus,
    isMarketStatusLoading,
    epsData,
    epsDataLoading: isEPSDataLoading,
    analystEstimates,
    isAnalystEstimatesLoading,
    refetchLastMarketOpenPrice,
    refetchSlideSteps,
    refetchEpsData,
    refetchPriceLikelihood,
    refetchRiskRewardChartData,
    refetchScenarioPotentials,
    refetchMarketStatus,
    refetchAnalystEstimates,
    refetchMarketComparison,
    isScenarioLoading
  }} data-sentry-element="unknown" data-sentry-component="ScenarioProvider" data-sentry-source-file="ScenarioProvider.tsx">
      {children}
    </ScenarioContext.Provider>;
}
export const useScenario = () => {
  const context = useContext(ScenarioContext);
  if (!context) {
    throw new Error("useScenario must be used within a ScenarioProvider");
  }
  return context;
};