import(/* webpackMode: "eager", webpackExports: ["CurrentPriceProvider"] */ "/vercel/path0/app/(home)/_components/CurrentPriceProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(home)/_components/HomeStockPriceCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(home)/_components/NoUserYet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PotentialValueSliderWrapped"] */ "/vercel/path0/app/(home)/_components/PotentialValueSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(home)/_components/ScenarioProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/components/ui/separator.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
