"use client";

import { trpc } from "@/providers/TrpcProvider";
import { LoaderCircleIcon } from "lucide-react";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
export default function NoUserYet() {
  const router = useRouter();
  const {
    data
  } = trpc.users.get.useQuery(undefined, {
    retry: 100,
    retryDelay: 1000
  });
  useEffect(() => {
    if (data) {
      router.refresh();
    }
  }, [data, router]);
  return <div className="flex flex-col gap-4 items-center justify-center py-20" data-sentry-component="NoUserYet" data-sentry-source-file="NoUserYet.tsx">
      <div className="flex flex-col gap-4 items-center justify-center text-center">
        <LoaderCircleIcon className="w-10 h-10 animate-spin" data-sentry-element="LoaderCircleIcon" data-sentry-source-file="NoUserYet.tsx" />
        <p>Authorizing...</p>
      </div>
    </div>;
}