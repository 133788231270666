"use client";

import { Toaster } from "@/components/ui/sonner";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import TrpcProvider, { trpc } from "./TrpcProvider";
import { useAuth, useUser } from "@clerk/nextjs";
import { useEffect } from "react";
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: "always" // or 'always' to create profiles for anonymous users as well,
  });
}
const AppProvider: React.FC<{
  children: any;
}> = ({
  children
}) => {
  return <TrpcProvider data-sentry-element="TrpcProvider" data-sentry-component="AppProvider" data-sentry-source-file="app.tsx">
      <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-source-file="app.tsx">
        <WrappedBody data-sentry-element="WrappedBody" data-sentry-source-file="app.tsx">{children}</WrappedBody>
      </PostHogProvider>
      <Toaster data-sentry-element="Toaster" data-sentry-source-file="app.tsx" />
    </TrpcProvider>;
};
const WrappedBody = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    userId
  } = useAuth();
  const {
    data: user
  } = trpc.users.get.useQuery(undefined, {
    enabled: !!userId
  });
  const localPosthog = usePostHog();
  useEffect(() => {
    if (user) {
      localPosthog.identify(user.id, {
        email: user.email,
        name: user.firstName + " " + user.lastName
      });
    }
  }, [user]);
  return <>{children}</>;
};
export default AppProvider;